import React, { useReducer } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { STATUSES } from "apis/UptimeRobot";
import {
  Layout, Meta, Section, Server,
} from "components";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: `${theme.spacing(2)}px !important`,
  },
  section: {
    paddingTop: 0,
  },
  success: { backgroundColor: theme.palette.success.dark },
  warning: { backgroundColor: theme.palette.warning.dark },
  error: { backgroundColor: theme.palette.error.dark },
}));

const SERVER_DATA = {
  general: {
    "m785611202-00a0b2e6a9ccd60bcb6dc367": {
      name: "Teamspeak",
      href: "/join#teamspeak",
      address: "ts.semtekdivision.com",
    },
    "m785611219-d3a56e082a56b2d28ee1dc52": {
      name: "Pterodactyl",
    },
  },
  game: {
    "m787809470-b9bf4198637d3c9f208430a6": {
      name: "Insurgency 1",
      address: "s.semtekdivision.com:27102",
    },
    "m785611184-2d5be81b1833db87c991bb9b": {
      name: "Minecraft 1",
      address: "s.semtekdivision.com:25565",
    },
    "m786519496-a8d443fe3beb9aa7edc7c210": {
      name: "Minecraft 2",
      address: "s.semtekdivision.com:25566",
    },
    "m786179002-98349dea8fb1f6edb40819a2": {
      name: "Stationeers 1",
      address: "s.semtekdivision.com:27500",
    },
  },
};

/**
 * Render status page.
 * @returns {JSX.Element} component
 */
export default function Page() {
  const [status, dispatch] = useReducer(reducer, {});
  const classes = useStyles();

  /**
   * Render servers data into components.
   * @param {Record<string, Record<string, Object>>} data data
   */
  function getServers(data) {
    return Object.entries(data)
      .map(([apiKey, serverInfo], key) => {
        const { name, href, address } = serverInfo;
        return (
          <Server {...{
            key, name, href, address, apiKey, dispatch,
          }}
          />
        );
      });
  }

  const generalServices = getServers(SERVER_DATA.general);
  const gameServices = getServers(SERVER_DATA.game);

  return (
    <Layout>
      <Meta
        title="Status"
        description="Check the real-time status of our infrastructure."
      />
      <Section spacing="small" maxWidth="medium">
        <Typography variant="h2" align="center">
          System Status
        </Typography>
        <Typography variant="h3" align="center">
          Check the real-time status of our infrastructure.
        </Typography>
        <ServiceAvailability status={status} />
      </Section>
      <Section
        spacing="small"
        maxWidth="medium"
        className={classes.section}
      >
        <Typography variant="h3">
          Communication and Control
        </Typography>
        { generalServices }
      </Section>
      <Section
        spacing="small"
        maxWidth="medium"
        className={classes.section}
      >
        <Typography variant="h3">
          Game Servers
        </Typography>
        { gameServices }
      </Section>
    </Layout>
  );
}

const AVAILABILITY = {
  ALL: 1,
  DEGRADED: 2,
  OUTAGE: 3,
};
const AVAILABILITY_TEXT = {
  [AVAILABILITY.ALL]: "All services available",
  [AVAILABILITY.DEGRADED]: "Degraded service availability",
  [AVAILABILITY.OUTAGE]: "Widespread service outage",
};

/**
 * Render service availability.
 * @param {Object} props props
 * @param {Record<string, number>} props.status server statuses
 * @returns {JSX.Element} component
 */
function ServiceAvailability({ status }) {
  const classes = useStyles();
  const totalServices = Object.keys(status).length;
  const operationalServices = Object.values(status)
    .filter((serviceStatus) => ![STATUSES.SEEMS_DOWN, STATUSES.DOWN].includes(serviceStatus))
    .length;
  const operationalPercentage = operationalServices / totalServices;

  const AVAILABILITY_CLASSES = {
    [AVAILABILITY.ALL]: classes.success,
    [AVAILABILITY.DEGRADED]: classes.warning,
    [AVAILABILITY.OUTAGE]: classes.error,
  };

  let availability;
  if (operationalPercentage < 0.3) {
    availability = AVAILABILITY.OUTAGE;
  } else if (operationalPercentage < 1) {
    availability = AVAILABILITY.DEGRADED;
  } else {
    availability = AVAILABILITY.ALL;
  }

  return (
    <Card classes={{ root: AVAILABILITY_CLASSES[availability] }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="h4" variantMapping={{ h4: "h3" }}>
          { AVAILABILITY_TEXT[availability] }
        </Typography>
      </CardContent>
    </Card>
  );
}

/**
 * Server statuses reducer.
 * @param {Object} state state object
 * @param {Object} action dispatcher action
 * @returns {Object} new state object
 */
function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "update": {
      const { id, status } = payload;
      return { [id]: status, ...state };
    }
    default: {
      return { ...state };
    }
  }
}
